import React from 'react';
import PageSection from '../../../components/Sections/PageSection';
import PageSectionTitle from '../../../components/Sections/PageSectionTitle';
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { SectionItem } from './DataMigrationSectionItem';

interface CennikDataMigrationSectionProps {
  systems: string[];
  data: string[];
}

const CennikDataMigrationSection: React.FC<CennikDataMigrationSectionProps> = ({ data, systems }) => {
  const theme = useTheme();

  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <PageSection maxWidth='lg'>
      <PageSectionTitle>Migracja danych</PageSectionTitle>
      <Typography variant='body1'>
        Jeśli dane do migracji, będą przygotowane zgodnie z CSV wg formatu wymaganego przez Mediportę, jesteśmy w stanie
        zaimportować dane z praktycznie wszystkich systemów funkcjonujacych na rynku. Również rodzaje danych jakie
        migrujemy są bardzo szerokie.
      </Typography>

      <Stack
        direction={isSmallerThanMd ? 'column' : 'row'}
        mt={4}
        gap={isSmallerThanMd ? 3 : 0}
        justifyContent={'space-evenly'}
      >
        <SectionItem title='Systemy, z których migrujemy dane:' items={systems} />
        <SectionItem title='Rodzaje danych, które migrujemy:' items={data} />
      </Stack>
    </PageSection>
  );
};

export default CennikDataMigrationSection;
