import SettingsIcon from '@mui/icons-material/Settings';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineDot, TimelineContent } from '@mui/lab';
import { SxProps, Typography } from '@mui/material';
import React from 'react';
import PageSection from '../../components/Sections/PageSection';
import PageSectionTitle from '../../components/Sections/PageSectionTitle';

type Step = {
  title: string;
  content: string;
  icon: React.ReactElement;
};

const iconSx: SxProps = { fontSize: '3rem', color: 'primary.main' };

const steps: Step[] = [
  {
    content: `
        Przygotowujemy konfigurację systemu,
        która zgodnie z ustaleniami odwzoruje strukturę organizacyjną Państwa placówki, 
        tworzymy konta użytkowników dla pracowników Przychodni, 
        konfigurujemy schemat uprawnień i dostępów do danych.`,
    title: 'Konfiguracja systemu',
    icon: <SettingsIcon sx={iconSx} />
  },
  {
    content: `
        Przenosimy dane pacjentów z Państwa aktualnego programu.
        Posiadamy bogate doświadczenie w migracji szerokiego zakresu danych ze wszystkich wiodących systemów: 
        pacjentów, adresów, rozpoznań i procedur, recept, terminów przyszłych wizyt, skierowań,
        kolejek oczekujących – a w razie potrzeby również innych informacji.`,
    icon: <DriveFileMoveIcon sx={iconSx} />,
    title: 'Przeniesienie danych'
  },
  {
    content: `
        Przeprowadzamy dla Państwa szczegółowe szkolenie przygotowujące Państwa pracowników do pracy z systemem.
        Rekomendowaną formą szkolenia jest spotkanie on-line w formie warsztatów, 
         które umożliwiają wszystkim uczestnikom samodzielne przećwiczenie korzystania z przedstawionych funkcji. 
        W tym celu możemy udostępnić osobną testową kopię systemu, aby próbne działania nie miały wpływu na bieżącą pracę.`,
    icon: <CastForEducationIcon sx={iconSx} />,
    title: 'Szkolenie administratorów i użytkowników'
  },
  {
    content: `
        Po etapie wdrożenia oferujemy stałe wsparcie techniczne. 
        Na tym etapie na bieżąco monitorujemy przepisy prawne i dostosowujemy do nich system poprzez automatyczne aktualizacje. 
        Oferujemy również usługi analizy powdrożeniowej,
        której celem jest odkrycie dodatkowych potrzeb wynikających z pierwszych doświadczeń w pracy z nowym programem.`,
    icon: <SupportAgentIcon sx={iconSx} />,
    title: 'Obsługa powdrożeniowa'
  }
];

const Item: React.FC<{ step: Step }> = ({ step }) => {
  return (
    <TimelineItem>
      <TimelineSeparator sx={{ color: 'background.default' }}>
        <TimelineConnector />
        <TimelineDot sx={{ backgroundColor: 'background.default' }}>{step.icon}</TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent
        display='flex'
        flexDirection='column'
        gap={1.5}
        my={2}
        sx={{
          paddingLeft: '1.5rem !important'
        }}
      >
        <Typography variant='h6' component='span'>
          {step.title}
        </Typography>
        <Typography>{step.content}</Typography>
      </TimelineContent>
    </TimelineItem>
  );
};

export const CennikCooperationStartSection = () => {
  return (
    <PageSection maxWidth='lg' bgcolor='primary.main' color='primary.contrastText'>
      <PageSectionTitle>Jak wygląda rozpoczęcie współpracy?</PageSectionTitle>
      <Typography variant='h4' component={'h3'} mb={1}>
        Przygotowanie systemu do pracy
      </Typography>
      <Typography mb={4}>
        Na podstawie otrzymanych danych przygotowujemy system dla Państwa placówki. Czynności wdrożeniowe i ich zakres
        podlegają indywidualnej wycenie, zgodnie z Twoimi potrzebami. Są to koszty jednorazowe, ustalane na etapie
        przygotowania oferty.
      </Typography>
      <Typography variant='h4' component={'h3'} mb={2}>
        Etapy wdrożenia
      </Typography>

      <Timeline
        sx={{
          '& .MuiTimelineItem-root:before': {
            flex: 0,
            padding: 0
          },
          '& .MuiTimelineItem-root': {
            minHeight: 100
          }
        }}
      >
        {steps.map((step, i) => (
          <Item key={i} step={step} />
        ))}
      </Timeline>
    </PageSection>
  );
};
